<template>
    <div class="dictionary-page">
        <div class="page-title">
            <div class="ctrl-btns">
                <a-button type="primary" size="small" @click="showAdd"><span class="iconfont icon-piliangtianjia"></span>新增来源</a-button>
            </div>
            <span class="name">来源字典</span>
        </div>

        <div class="table-wrap">
            <a-table 
                :columns="columns" 
                :data-source="list" 
                :rowKey="(record)=>record.sourceId" 
                :pagination="{hideOnSinglePage:true,total:total,current}" 
                :bordered="false"
                @change="changePage"
                >
                <template slot="actions" slot-scope="text,record">
                    <a-button type="link" @click="edit(record)">编辑</a-button>
                </template>
            </a-table>
        </div>

        <a-modal 
            :title="editKey?'编辑来源':'新增来源'"
            :visible="visbile"
            centered
            :footer="null"
            wrapClassName="edit-cube-win"
            @cancel="cancelAddDimension">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">出版物</div></a-col>
                <a-col flex="auto">
                    <a-input v-model="name" style="width:100%;" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">出版机构</div></a-col>
                <a-col flex="auto">
                    <a-input v-model="org" style="width:100%;" />
                </a-col>
            </a-row>
            <div class="btns">
                <a-button @click="cancelAddDimension">取消</a-button>
                <a-button type="primary" @click="addDimension">确定</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { messageTips } from '../../until/tools';
const columns = [
    {
        title: '序号',
        dataIndex: 'sourceId',
        key: 'sourceId',
        scopedSlots: { customRender: 'sourceId' },
    },
    {
        title: '出版物',
        dataIndex: 'sourceName',
        key: 'sourceName',
    },
    {
        title: '出版机构',
        dataIndex: 'memo',
        key: 'memo',
    },
    {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
    },
];
export default {
    data() {
        return {
            columns,
            list:[],
            editKey:null,
            visbile:false,
            name:'',
            org:'',
            total:0,
            current:1
        }
    },
    created() {
        this.getList();
    },
    methods:{

        cancelAddDimension() {
            this.visbile = false;
            this.org = '';
            this.name = '';
            this.editKey = null;
        },
        showAdd() {
            this.visbile = true;
        },
        getList(page=1) {
            this.$http.getSource({currentPage:page}).then(res=>{
                if(res&&res.list) {
                    this.list = res.list;
                    this.total = res.page.allSize;
                    this.current = page;
                }
            });
        },
        addDimension() {
            if(this.name==='') {
                messageTips('请输入出版物',2);
                return false;
            }
            if(this.org==='') {
                messageTips('请输入出版机构',2);
                return false;
            }
            let params = {
                sourceName:this.name,
                lang:1,
                memo:this.org
            }
            let funcName;
            if(this.editKey) {
                params.sourceId = this.editKey;
                funcName = 'editSource';
            } else {
                funcName = 'addSource';
            }
            this.$http[funcName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        this.visbile = false;
                        messageTips(res.message,3);
                        this.getList();
                        this.name = '';
                        this.org = '';
                        this.editKey = null;
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        edit(item) {
            this.name = item.sourceName;
            this.org = item.memo;
            this.editKey = item.sourceId;
            this.visbile = true;
        },
        multiDel() {
            this.del(this.selectedRowKeys.join(','));
        },
        changePage(e) {
            this.getList(e.current)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/inputs/dictionary.scss';
</style>